<template>
  <DrupalLink
    :to="to"
    class="relative block aspect-square w-full items-center bg-grey-light-03 px-16 pb-12 pt-16 align-middle transition duration-300 ease-in-out hover:bg-grey-light-02 md:px-32 md:pb-24 md:pt-[21px]"
    :data-id="id"
    @click="trackProductClick(variation, strap ? 'Strap' : 'Watch')"
  >
    <div class="absolute left-16 w-full md:left-32 md:top-24 md:h-32">
      <div class="flex gap-24">
        <template
          v-for="pillText in [
            isNew ? $texts('new', 'New') : undefined,
            limited && !noLimitedPill
              ? $texts('limited', 'Limited')
              : undefined,
          ]"
        >
          <p
            v-if="pillText"
            class="w-fit rounded-full bg-white px-[13px] text-[12px] text-grey-dark-02 mobile-only:py-6 md:px-16 md:py-10 md:text-xs"
          >
            {{ pillText }}
          </p>
        </template>
      </div>
    </div>
    <div class="flex h-full w-full flex-col justify-between">
      <!-- used as spacer to position the image -->
      <div
        class="h-0 w-full shrink"
        :class="{
          'md:h-30': !strap,
        }"
      ></div>
      <img
        v-if="image"
        loading="lazy"
        class="m-auto object-contain md:w-[90%]"
        :class="{
          'mobile-only:h-[78.5%] md:h-[72.6%]': strap,
        }"
        :src="image"
      />
      <!-- used as spacer to position the image -->
      <div
        class="w-full shrink"
        :class="{
          'h-10 md:h-[19px]': !strap,
          'h-[20px] md:h-[39px]': strap,
        }"
      ></div>
      <div class="flex max-h-72 w-full flex-col justify-between">
        <div class="mb-2 md:mb-8" :class="strap ? 'h-[27px]' : 'h-[48px]'">
          <p
            class="line-clamp-2 text-[16px] uppercase text-black md:text-[18px] md:leading-6"
          >
            {{ title }}
          </p>
        </div>
        <div class="flex flex-1 justify-between">
          <p v-if="tagline" class="leading-0 text-sm text-grey-dark-01">
            {{ tagline }}
          </p>
          <CommercePrice
            v-if="variation.price"
            :price="variation.price"
            class="leading-0 ml-auto text-sm text-grey-dark-02"
          />
        </div>
      </div>
    </div>
  </DrupalLink>
</template>

<script setup lang="ts">
import { type ProductFinderItemFragment } from '#graphql-operations'
import { type AnyProductVariationTeaser } from '~/types/product'

const { trackProductClick } = useAnalyticsTracking()

const props = defineProps<{
  variation: AnyProductVariationTeaser | ProductFinderItemFragment
  strap?: boolean
  noLimitedPill?: boolean
}>()

const limited = computed(
  () =>
    'isLimitedEdition' in props.variation && props.variation.isLimitedEdition,
)
const isNew = computed(
  () => 'isNew' in props.variation && props.variation.isNew,
)
const to = computed(() =>
  typeof props.variation.url === 'string'
    ? props.variation.url
    : props.variation.url?.path,
)
const id = computed(() => props.variation.id)
const image = computed(() => {
  return (
    ('imageUrl' in props.variation && props.variation.imageUrl) ||
    ('image' in props.variation && props.variation.image)
  )
})
const title = computed(() => props.variation.title)
const tagline = computed(() => {
  return 'size' in props.variation
    ? props.variation.size
    : 'interhornWidth' in props.variation && props.variation.interhornWidth
})
</script>
